import offside from 'offside-js';
import Glide from '@glidejs/glide';
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/rias/ls.rias';
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import MicroModal from 'micromodal';
import ScrollOut from "scroll-out";

const page = document.documentElement.getAttribute('class');

const slideDirection = document.documentElement.getAttribute('dir'); 

/* 
 * COMMON
 */

// SECTION About
var aboutSection = offside( '#aboutPanel', {
  buttonsSelector: '.toggler-about',
  slidingSide: 'top',
  debug: false,
  beforeOpen: function() {
    glideFocus.enable();
  },
  afterOpen: function() {
    _toggleActiveState( document.querySelector('.hamburger') );
    _removeA11y( document.querySelector('#aboutPanel') );
  },
  afterClose: function() {
    glideFocus.disable();
    _toggleActiveState( document.querySelector('.hamburger') );
    _addA11y( document.querySelector('#aboutPanel') );
  } 
});

// -- Init Slider
var glideFocus = new Glide('#glide-focus', {direction: slideDirection});

glideFocus.on(['mount.after', 'run'], function () {
  document.querySelector('#glideFocusIndex').innerHTML = glideFocus.index + 1;
});

glideFocus.mount();
glideFocus.disable();

switch (page) {
  case 'home':
    /*
     * HOME
     */

    // Scroll Animation Test
    ScrollOut({
      scrollingElement: ".panel-offcanvas"
    });

    // SECTION Category 1
    // --------------------
    var cat1Section = offside( '#category1Panel', {
      buttonsSelector: '.toggler-cat1',
      slidingSide: 'left',
      debug: false,
      /* beforeOpen: function() {
        videoPlayer.pause();
      }, */
      afterOpen: function() {
        _removeA11y( document.querySelector('#category1Panel') );
        document.querySelector('#category1Panel').focus();
      },
      afterClose: function() {
        //
        _addA11y( document.querySelector('#category1Panel') );
      }
    });

    // -- Init Slider
    var slidersCat1 = document.querySelectorAll('.glide-cat1');

    for (var i = 0; i < slidersCat1.length; i++) {
      var glideCat1 = new Glide(slidersCat1[i], {direction: slideDirection});

      glideCat1.mount();
    }

    // SECTION Category 2
    // --------------------
    var cat2Section = offside( '#category2Panel', {
      buttonsSelector: '.toggler-cat2',
      slidingSide: 'right',
      debug: false,
      afterOpen: function() {
        _removeA11y( document.querySelector('#category2Panel') );
        document.querySelector('#category2Panel').focus();
      },
      afterClose: function() {
        //
        _addA11y( document.querySelector('#category2Panel') );
      }
    });

    //-- Init Slider
    var slidersCat2 = document.querySelectorAll('.glide-cat2');

    for (var j = 0; j < slidersCat2.length; j++) {
      var glideCat2 = new Glide(slidersCat2[j], {direction: slideDirection});
      
      glideCat2.mount();
    }

    //-- Init Modal
    MicroModal.init({
      onShow: _homeOpenModal,
      onClose: _homeCloseModal,
      disableScroll: true,
      disableFocus: false,
      //awaitOpenAnimation: true,
      //awaitCloseAnimation: false,
      debugMode: false
    });

    break;

    case 'collections':
    
     break;
}


/* 
 * HELPERS
 */

//  A11Y
function _addA11y (panel) {
  //  add aria-hidden attribute
  panel.setAttribute('aria-hidden', true);
}

function _removeA11y (panel) {
  //  remove aria-hidden attribute
  //panel.removeAttribute('aria-hidden');
  panel.setAttribute('aria-hidden', false);
}

function _toggleActiveState(button) {
  button.classList.toggle('is-active');
}

// Modal
function _homeOpenModal(modal, activeElement, event) {
  //var gallery = event.target.parentNode.closest('aside');
  var gallery = event.target;
  var modalGallery = gallery.cloneNode(true);
  modalGallery.classList.remove('lazyloaded');
  modalGallery.removeAttribute('srcset');
  document.getElementById('modal-1-content').appendChild( modalGallery ).classList.add('lazyload');
  lazySizes.autoSizer.checkElems();
  //lazySizes.init();
}

function _homeCloseModal(modal, activeElement, event) {
  document.getElementById('modal-1-content').innerHTML = "";
}
